.main {
  width: 100%;
  background: #11b8ee99; /* Keeping the same background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
  position: relative;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Logo */
.logo {
  height: 50px;
  width: 50px;
  object-fit: contain;
  background: #a2d6ed;
  border-radius: 50%;
}

/* Center the links properly */
.navmenu {
  display: flex;
  justify-content: center;
  gap: 30px; /* Space between links */
  color: white;
  text-transform: capitalize; /* Match text styling from the image */
}

.link {
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.link:hover {
  color: #fff; /* Highlight the link when hovered */
}

.link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(255, 254, 254);
  bottom: -5px; /* Adjusted to appear just below the text */
  left: 50%;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.link:hover::after {
  width: 100%;
  left: 0;
}

/* Hamburger menu hidden by default */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
}

/* Mobile view styling */
@media (max-width: 768px) {
  .navmenu {
    display: none; /* Hide nav links in mobile view */
    flex-direction: column;
    background-color: #11b8ee;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    gap: 20px;
    padding: 20px 0;
    text-align: center;
    z-index: 1000;
  }

  .navmenu.active {
    display: flex;
  }

  .hamburger {
    display: flex; /* Show hamburger icon on small screens */
  }
}
