.course_details_come {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  padding: 30px;
  background-color: #1e1e1e;
  color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 62vh;
  border: 1px solid #90c3c942;
}

@keyframes slideIn {
  60% {
    opacity: 1;
    transform: translateY(50%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}

.course_details_come .object {
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 0;
}

.course_details_come .object p {
  margin: 0;
  font-weight: 500;
}

.course_details_come .head_text {
  font-weight: 600;
  font-size: 22px;
  margin: 15px 0;
  color: #00ffa5;
}

.course_details_come .logo {
  color: #00d4ff;
  font-size: 20px;
}

.course_details_come .lecture_object {
  margin-left: 20px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #dddddd;
}

.course_details_come .lecture_object p {
  margin: 0;
}
.course_details_come .container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.course_details_come .objects {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #292929;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.course_details_come .value {
  margin-top: 9px;
  color: #00a4ea;
  font-size: 14px;
  word-break: break-all;
}

.course_details_come .value:hover {
  text-decoration: underline;
}

.course_details_come .text {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.course_details_come::-webkit-scrollbar {
  width: 2px;
}

.course_details_come::-webkit-scrollbar-track {
  background: #444444;
}

.course_details_come::-webkit-scrollbar-thumb {
  background-color: #74e1e6;
  border-radius: 10px;
}

.course_details_come::-webkit-scrollbar-thumb:hover {
  background: #aff0f8;
}

@media (max-width: 768px) {
  .course_details_come {
    padding: 20px;
    height: 50vh;
    overflow-y: auto;
  }
  .objects {
    padding: 15px;
  }
  .head_text {
    font-size: 18px;
  }
  .lecture_object {
    font-size: 14px;
    margin-left: 10px;
  }
}
