.textMain {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 50px;
    height: 74vh;
    overflow-y: scroll;
    color: #2be8b9de;
}

.textMain::-webkit-scrollbar {
    width: 1px;
}

.textMain::-webkit-scrollbar-track {
    background: transparent;
}

.textMain::-webkit-scrollbar-thumb {
    background-color: #90f3ee1a;
    border-radius: 10px;
}

.textMain::-webkit-scrollbar-thumb:hover {
    background: #74e1e6;
}

.textOne {
    border: 0.01px solid #74e1e6;
    padding: 34px;
    border-radius: 9px;
}

.textTwo {
    border: 0.01px solid #74e1e6;
    padding: 34px;
    border-radius: 9px;
}