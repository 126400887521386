.container {
  width: 100%;
  padding: 31px;
  margin: 0 auto;
  color: white;
  background: #141414;
}

.wrapper {
  display: flex;
  gap: 6rem;
  justify-content: center;
}

/* Responsive styling for screens smaller than 768px */
@media (max-width: 768px) {
  .wrapper {
      flex-direction: column; /* Stack items vertically */
      gap: 2rem; /* Reduce gap for mobile view */
      align-items: center; /* Center items */
  }

  .container {
      padding: 20px; /* Adjust padding for smaller screens */
  }
}

.tag {
  position: relative;
  cursor: pointer;
}

.tag::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(8, 211, 237);
  top: 30px;
  left: 50%;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.tag:hover::after {
  width: 100%;
  left: 0;
}

/* Responsive styling for tag elements on mobile */
@media (max-width: 768px) {
  .tag {
      font-size: 1.2rem; /* Adjust font size for mobile */
  }

  .tag::after {
      top: 25px; /* Adjust positioning of underline effect */
  }
}
@media (max-width: 480px) {
  .container {
    width: 100%;
    margin: 0 auto;
    color: white;
    background: #141414;
  }
  
}