.wrapper {
    border: 0.01px solid #90c3c942;
    width: 57%;
    margin: 10rem auto;
    padding: 50px;
}

/* Media query for tablets (screens smaller than 768px) */
@media (max-width: 768px) {
    .wrapper {
        width: 80%; /* Adjust width for tablets */
        padding: 30px; /* Reduce padding for tablets */
    }
}

/* Media query for mobile devices (screens smaller than 576px) */
@media (max-width: 576px) {
    .wrapper {
        width: 90%; /* Further adjust width for mobile */
        padding: 20px; /* Reduce padding for mobile */
    }
}

.container {
    width: 50%;
    margin: 0 auto;
}

/* Media query for tablets */
@media (max-width: 768px) {
    .container {
        width: 80%; /* Adjust width for tablets */
    }
}

/* Media query for mobile devices */
@media (max-width: 576px) {
    .container {
        width: 90%; /* Further adjust width for mobile */
    }
}

.headText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: 6px;
    padding: 16px;
    color: #9e979730;
    letter-spacing: 2px;
    font-size: 2rem;
}

/* Media query for tablets */
@media (max-width: 768px) {
    .headText {
        font-size: 1.5rem; /* Adjust font size for tablets */
        padding: 12px; /* Reduce padding for tablets */
    }
}

/* Media query for mobile devices */
@media (max-width: 576px) {
    .headText {
        font-size: 1.2rem; /* Further reduce font size for mobile */
        padding: 8px; /* Reduce padding for mobile */
    }
}
