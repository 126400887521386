/* Modal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
}

.modalContent {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    height: 50%;
    max-width: 800px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    animation: slideIn 0.3s ease-out;
    transition: all 0.3s ease-in-out;
}
.modalContent::-webkit-scrollbar {
    width: 8px;
  }
  
  .modalContent::-webkit-scrollbar-track {
    background: #1c1c1c;
  }
  
  .modalContent::-webkit-scrollbar-thumb {
    background-color: #39ff14; /* Fluorescent green for the scrollbar thumb */
    border-radius: 10px;
  }
  
  .modalContent::-webkit-scrollbar-thumb:hover {
    background-color: #32d300; /* Slightly lighter green on hover */
  }
  
  .modalContent::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  
.modalTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.closeButton {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ff0000;
}

.testDetails {
    margin-bottom: 20px;
}

.testDetails div {
    margin-bottom: 12px;
}

.testDetails label {
    font-weight: 500;
    margin-right: 10px;
    font-size: 16px;
}
.selectWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.label {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
}

.selectInput {
    padding: 10px 15px;
    font-size: 1rem;
    border: 2px solid #ccc;
    border-radius: 6px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: border 0.3s, background-color 0.3s, box-shadow 0.3s;
    
    /* &:focus {
        outline: none;
        border-color: #33dcc8;
        background-color: #eaf6f3;
        box-shadow: 0 0 10px rgba(51, 220, 200, 0.3);
    } */
    
    option {
        padding: 10px;
        font-size: 1rem;
    }
}

input,
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
}

input:focus,
textarea:focus {
    border-color: #33dcc8b4;
    outline: none;
    box-shadow: 0 0 5px rgba(51, 220, 200, 0.5);
}

textarea {
    resize: vertical;
    height: 100px;
}

.questionsContainer {
    margin-top: 20px;
}

.questionCard {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.questionCard label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

button {
    width: 48%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    color: white;
    background: #33dcc8b4;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slideIn {
    0% { transform: translateY(-30px); }
    100% { transform: translateY(0); }
}
