/* General styles for the dropdown */
.regfields {
  outline: none;
  background: none;
  color: rgb(162, 162, 162);
  text-align: left;
  width: 100%;
  border: none; /* Adding border for better visibility */
  padding: 12px; /* More padding for larger screens */
  font-size: 16px;
  border-radius: 4px; /* Rounded corners for better appearance */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
/* Remove default arrow for a custom look */
}

/* Scrollbar styling */
.regfields {
  overflow-y: auto; /* Ensure scrollbar appears only when needed */
}

.regfields::-webkit-scrollbar {
  width: 6px; /* Scrollbar width */
}

.regfields::-webkit-scrollbar-track {
  background: #10c7e8; /* Scrollbar track color */
}

/* Handle */
.regfields::-webkit-scrollbar-thumb {
  background: #10c7e8; /* Scrollbar handle color */
}

/* Handle on hover */
.regfields::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker scrollbar handle on hover */
}

/* Hover state */
.regfields:hover {
  border-color: #10c7e8; /* Change border color on hover */
}

/* Focus state */
.regfields:focus {
  border-color: #007BFF; /* Change border color on focus */
}

/* Media queries for responsiveness */

/* For tablets (screens up to 768px) */

@media (max-width: 980px) {
  .regfields {
    font-size: 15px; /* Adjust font size for screens up to 980px */
    padding: 11px; /* Adjust padding */
  }
}
@media (max-width: 780px) {
  .regfields {
    font-size: 14px; /* Smaller font size */
    padding: 10px; /* Adjust padding */
    width: 100%; /* Ensure full width on smaller screens */
  }
}

/* For mobile devices (screens up to 576px) */
@media (max-width: 576px) {
  .regfields {
    font-size: 13px; /* Even smaller font size for mobile */
    padding: 8px; /* Reduce padding for mobile */
  }
}

/* For very small mobile devices (screens up to 480px) */
@media (max-width: 480px) {
  .regfields {
    font-size: 12px; /* Adjust font size for very small devices */
    padding: 6px; /* Minimal padding */
  }
}

/* For screens smaller than 320px (extra small mobile devices) */
@media (max-width: 320px) {
  .regfields {
    font-size: 11px;
    padding: 5px;
    width: 100%; /* Full width for tiny screens */
  }
}
