.dataform {
  margin: 4rem auto;
  width: 94%;
  border-radius: 3px;
  border: 0.2px solid #dafcfc26;
   
  } 
  .drop_container {
    border: 0.2px solid #a6f6f62e;
    display: flex;
    width: 98%;
    border-radius: 4px;
  }
  
  .addMoreButton   {
    width: fit-content;
    border-radius: 3px;
    padding: 12px;
    border: none;
    color: white;
    background: #33dcc8b4;
    cursor: pointer;
  }

  .regfields .class {
    color: black;
  
  }
  .container {
    display: flex;
    gap: 15px;
  }
  
  .dataform .crad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3em;
    padding: 2%;
    align-items: center;
  }

  .input_wrapper {
    border: 0.2px solid #a6f6f62e;
    padding: 20px;
    display: flex;
    width: 98%;
    border-radius: 4px;
  }
  
  .regfields {
    background: none;
    outline: none;
    color: white;
    border: none;
    width: 100%;
    text-align: left;
  }
  
  .regfields::placeholder {
    color:rgb(157, 157, 157);
  }
  
  .procedebutton {
    width: 6rem;
    border-radius: 6px;
    height: 3em;
    border: none;
    color: white;
    background: #33dcc8b4;
    cursor: pointer;
  }
  
  /* .procedebutton:hover {
    background: rgb(232 225 225 / 58%);
  } */
  
  .prog_icon_container {
    display: flex;
    justify-content: space-between;
    padding: 30px;
  }
  .icon {
    color: #00BCD4;
  }
  .prog_icon {
    color: #21e6ff82;
    font-size: 28px;
    cursor: pointer;
  }

  .subject_card_container {
    overflow-y: scroll;
    height: 50vh;
  }

  .subject_card_container::-webkit-scrollbar {
    width: 2px; /* width of the scrollbar */
  }
  
  .subject_card_container::-webkit-scrollbar-track {
    background: transparent; /* color of the scrollbar track */
  }
  
  .subject_card_container::-webkit-scrollbar-thumb {
    background-color: #06e2ffaf; /* color of the scrollbar thumb */
    border-radius: 10px; /* roundness of the scrollbar thumb */
  }
  
  .subject_card_container::-webkit-scrollbar-thumb:hover {
    background: #444343; /* color of the scrollbar thumb on hover */
  }

  .drop_container {
    width: 98%;
    border: 0.2px solid #a6f6f62e;
    border-radius: 4px;
    padding: 20px;
  }