
/* Container for dropdowns */
.container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  padding: 26px;
  margin: 0 auto;
  gap: 20px; /* Reduced gap for smaller screens */
  background: #000000;
}

/* Dropdown container */
.drop_container {
  flex: 1 1 200px; /* Allow flexibility and set a min width */
  border: 0.001px solid #ffffff66;
  padding: 12px;
  border-radius: 6px;
}

/* Button container */
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* View button */
.view {
  width: auto; /* Allow button to adjust width */
  min-width: 100px; /* Set a minimum width */
  border-radius: 6px;
  padding: 10px;
  border: none;
  color: white;
  background: #33dcc8b4;
  cursor: pointer;
  margin: 8px auto;
}

/* Table container styles */
.tableContainer {
  margin: 0 auto;
  width: 80%;
  margin-top: 20px; /* Add spacing above the table */ /* Allow horizontal scrolling on smaller screens */
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
      padding: 16px;
      gap: 16px; /* Reduce gap further on smaller screens */
  }

  .drop_container {
      flex: 1 1 150px; /* Reduce the minimum width for smaller screens */
  }
  
  .view {
      padding: 8px; /* Adjust padding for smaller buttons */
      font-size: 0.9rem; /* Smaller font size */
  }
}

@media (max-width: 480px) {
  .container {
      flex-direction: column; /* Stack dropdowns vertically */
      align-items: center; /* Center align items */
  }

  .drop_container {
      width: 90%; /* Full width on small screens */
      margin-bottom: 10px; /* Space between dropdowns */
  }

  .view {
      width: 100%; /* Full width button */
      min-width: 0; /* Remove min-width for small screens */
      padding: 10px; /* Slightly larger padding */
  }

  .buttonContainer {
      margin-top: 10px; /* Add space above the button container */
  }
}
@media (max-width: 540px) {
  .container {
      flex-direction: column; /* Stack dropdowns vertically */
      align-items: center; /* Center align items */
  }

  .drop_container {
      width: 90%; /* Full width on small screens */
      margin-bottom: 10px; /* Space between dropdowns */
  }

  .view {
      width: 100%; /* Full width button */
      min-width: 0; /* Remove min-width for small screens */
      padding: 10px; /* Slightly larger padding */
  }

  .buttonContainer {
      margin-top: 10px; /* Add space above the button container */
  }
}
@media (max-width: 520px) {
  .container {
      flex-direction: column; /* Stack dropdowns vertically */
      align-items: center; /* Center align items */
  }

  .drop_container {
      width: 90%; /* Full width on small screens */
      margin-bottom: 10px; /* Space between dropdowns */
  }

  .view {
      width: 100%; /* Full width button */
      min-width: 0; /* Remove min-width for small screens */
      padding: 10px; /* Slightly larger padding */
  }

  .buttonContainer {
      margin-top: 10px; /* Add space above the button container */
  }
}
@media (max-width: 380px) {
  .container {
      flex-direction: column; /* Stack dropdowns vertically */
      align-items: center; /* Center align items */
  }

  .drop_container {
      width: 90%; /* Full width on small screens */
      margin-bottom: 10px; /* Space between dropdowns */
  }

  .view {
      width: 100%; /* Full width button */
      min-width: 0; /* Remove min-width for small screens */
      padding: 10px; /* Slightly larger padding */
  }

  .buttonContainer {
      margin-top: 10px; /* Add space above the button container */
  }
}
