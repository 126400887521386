.container {
  min-height: 100vh;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
}

.header {
  text-align: center;
  margin-bottom: 4rem;
}

.title {
  font-size: 3rem;
  color: #d6eded;
  margin-bottom: 1rem;
}

.underline {
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #2563eb, #3b82f6);
  margin: 0 auto;
  border-radius: 2px;
}

.section {
  margin-bottom: 4rem;
}

.sectionTitle {
  font-size: 2rem;
  color: #fdfeff;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 1rem;
}

.sectionTitle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 70%;
  background: #3b82f6;
  border-radius: 2px;
}

.text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4b5563;
  text-align: justify;
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.statsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.statBox {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.statBox:hover {
  transform: translateY(-5px);
}

.statNumber {
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  color: #2563eb;
  margin-bottom: 0.5rem;
}

.statLabel {
  color: #6b7280;
  font-size: 1.1rem;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.cardTitle {
  font-size: 1.5rem;
  color: #1f2937;
  margin-bottom: 1rem;
}

.cardText {
  color: #4b5563;
  line-height: 1.7;
}

.highlight {
  border-left: 4px solid #2563eb;
  background: linear-gradient(to right, #f0f7ff, white);
}

@media (max-width: 768px) {
  .main {
      padding: 2rem 1rem;
  }

  .title {
      font-size: 2.5rem;
  }

  .sectionTitle {
      font-size: 1.8rem;
  }

  .text {
      font-size: 1rem;
      padding: 1.5rem;
  }

  .statNumber {
      font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .title {
      font-size: 2rem;
  }

  .sectionTitle {
      font-size: 1.5rem;
  }

  .statsContainer {
      grid-template-columns: 1fr;
  }

  .cardContainer {
      grid-template-columns: 1fr;
  }
}