.main {
    display: flex;
    align-items: center;
    height: 88vh;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    align-items: center;
    padding: 18px;
    width: 50%;
    height: 74vh;
    overflow-y: scroll;
}

.cardContainer::-webkit-scrollbar {
    width: 1px;
}

.cardContainer::-webkit-scrollbar-track {
    background: transparent;
}

.cardContainer::-webkit-scrollbar-thumb {
    background-color: #90f3ee1a;
    border-radius: 10px;
}

.cardContainer::-webkit-scrollbar-thumb:hover {
    background: #74e1e6;
}