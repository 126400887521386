/* Add this CSS to your table styling module */
.table-container {
    overflow-x: auto; /* Allows horizontal scroll on smaller screens */
    width: 100%; /* Full width */
    margin: auto;
  }
  
  /* Mobile responsive styles */
  @media (max-width: 768px) {
    .table-container {
      padding: 10px;
    }
  
    .table th,
    .table td {
      padding: 8px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Reduce font size */
    }
  }
  
  /* Extra small screens */
  @media (max-width: 480px) {
    .table th,
    .table td {
      padding: 5px;
      font-size: 12px;
    }
  
    /* Stack buttons vertically */
    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  