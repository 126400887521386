@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@400;500;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color:#1e4057;
}

body select,
body option {
  background-color: rgba(0, 0, 0, 0.89);

}

body::-webkit-scrollbar {
  width: 2px;
}
body::-webkit-scrollbar-track {
  background: rgb(9, 151, 104);
}
body::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6);
}

