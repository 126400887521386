.formContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #111111;
    color: white;
    font-family: 'Arial', sans-serif;
    margin: 15px;
    border-radius: 4px;
  }
  .errorText {
    color: red;
    font-weight: 490;
  }
  .dropdownContainer {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;
}
.drop_container {
    width: 50%;
    border: 0.001px solid #ffffff66;
    padding: 12px;
    border-radius: 6px;
  }
  
  .card {
    background-color: #2c2c2c;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .inputField {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #3c3c3c;
    color: #fff;
    font-size: 16px;
  }
  
  .inputField::placeholder {
    color: #bbb;
  }
  
  .addButton {
    background-color: #444;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  
  .addButton:hover {
    background-color: #555;
  }
  .cardContainer {
    height: 19rem;
    overflow-y: scroll;
  }
  .cardContainer::-webkit-scrollbar {
    width: 3px;
  }
  
  .cardContainer::-webkit-scrollbar-track {
    background: #1c1c1c;
  }
  
  .cardContainer::-webkit-scrollbar-thumb {
    background-color: #39ff14; /* Fluorescent green for the scrollbar thumb */
    border-radius: 10px;
  }
  
  .cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: #32d300; /* Slightly lighter green on hover */
  }
  
  .cardContainer::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  
  .saveButton {
    background-color: #28a745;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }

  .buttonContainer {
    display: flex;
    gap: 15px;
    margin: 0 auto;
   
  }
  .optionTitle  {
    color: #00ffa5;
    font-weight: 550;
}
  
  .removeButton {
    background: transparent;
    border: none;
    color: #f51707;
    font-size: 24px;
}
  .head_text {
    font-weight: 600;
    font-size: 22px;
    margin: 15px 0;
    color: #00ffa5;
  }
  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    color: #00d4ff;
    font-size: 20px;
  }

  .saveButton:hover {
    background-color: #218838;
  }
  
  @media (max-width: 1024px) {
    .dropdownContainer {
        display: flex;
        flex-direction: column;
    }

    .drop_container {
        width: 100%;
      }

  }