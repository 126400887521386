/* components/Banner.module.css */
.bannerContainer {
    background-image: url('../images/26.jpg'); /* Replace with the actual image path */
    background-size: cover;
    background-position: center;
    height: 250px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
    margin-bottom: 30px; /* Space between banner and other content */
}

.bannerContent {
    max-width: 800px;
}

.bannerTitle {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.bannerText {
    font-size: 18px;
    margin-bottom: 20px;
}

.bannerButton {
    background-color: #0044cc;
    color: white;
    padding: 12px 30px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.bannerButton:hover {
    background-color: #0033aa;
}
