.uploader_table {
  width: 100%;
  padding: 25px;
  border: 0.01pc solid #00ffff52;
  
}

.header_panel {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header_panel .left_pane {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.header_panel .left_pane .table_title {
  color: #0072B8;
  text-align: left;
  font-size: 1.2rem;
}

.header_panel .right_pane {
  width: 100%;
  border: 0.001px solid #4cafa2;
  border-radius: 54px;
  padding: 10px;
}

.header_panel .right_pane .table_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header_panel .right_pane .table_search .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.header_panel .right_pane .table_search .uploader_table_input {
  padding: 6px 10px;
  width: 50%;
  color: white;
  border: none;
  outline: none;
  background: none;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
  width: 100%;
  padding: 12px;
}

.uploader-table th,
.uploader-table td {
  padding: 10px;
  border-bottom: 0.001px solid #8ce5e52e;
  text-align: left;
}

.uploader-table th {
  background-color: #f2f2f2;
}

.uploader-table tbody tr {
  background-color: #03f9a7;
}

.uploader-table button {
  margin-right: 5px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #405cf5;
  color: white;
  border: none;
  border-radius: 4px;
}

.uploader-table button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.toggleBar {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.toggleCircle {
  width: 16px;
  height: 16px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: left 0.3s ease;
}

.active {
  background-color: green;
}

.inactive {
  background-color: red;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .uploader_table {
    padding: 15px;
    font-size: 14px; /* Reduce font size for mobile */
  }

  .uploader_table th, .uploader_table td {
    padding: 8px;
  }

  .header_panel {
    flex-direction: column;
    align-items: flex-start;
  }

  .header_panel .left_pane,
  .header_panel .right_pane {
    width: 100%;
    margin-bottom: 10px; /* Add space between panels */
  }

  .uploader_table button {
    padding: 6px 12px; /* Adjust button size */
  }
}

@media (max-width: 480px) {
  .uploader_table {
    padding: 10px;
    font-size: 12px; /* Further reduce font size for smaller devices */
  }

  .uploader_table th, .uploader_table td {
    padding: 6px;
  }

  .uploader_table button {
    padding: 4px 8px; /* Further adjust button size */
  }

  .header_panel .right_pane .uploader_table_input {
    width: 100%; /* Full width input on mobile */
  }

  .header_panel {
    flex-direction: column;
  }

  .button_container {
    justify-content: center; /* Center buttons on mobile */
  }
}
