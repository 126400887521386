.tableContainer {
    width: 85%;
    margin: 0 auto;
    background-color: #000000;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .filterInput {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    box-sizing: border-box;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .table th, .table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
    color: #ffffff;
  }
  .table th {
    background-color: #007bff;
    color: #fff;
  }
  
  .table tbody tr:hover {
    background-color: #464646;
  }
  
  .icon {
    cursor: pointer;
    color: #007bff;
    margin-right: 10px;
  }
  
  .icon:hover {
    color: #0056b3;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 11px auto;
  }
  
  .pagination button {
    padding: 8px 12px;
    margin: 0 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  .pagination button.active {
    background-color: #0056b3;
  }
  
  .pagination button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  .arrowButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin: 0 5px;
  }
  
  .arrowButton:hover {
    color: #007bff;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modalContent button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modalContent button:hover {
    background-color: #c82333;
  }
  