.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.navbarWrapper {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scrollableContent {
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 80px;
  height: 100vh;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.title {
  font-size: 2.5rem;
  color: #b2e7e1;
  margin-bottom: 1rem;
}

.underline {
  height: 4px;
  width: 100px;
  background-color: #007bff;
  margin: 0 auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.heroSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.carousel {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.slideWrapper {
  position: relative;
  height: 400px;
}

.carouselImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.carouselControl {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 1rem;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.indicator.active {
  background-color: #007bff;
}

.missionSection {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.sectionTitle {
  font-size: 2rem;
  color: #444;
  margin-bottom: 1.5rem;
  text-align: center;
}

.text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
}

.highlight {
  background-color: #e8f4ff;
  padding: 1.5rem;
  border-radius: 8px;
  border-left: 4px solid #007bff;
}


.banner {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  filter: brightness(0.7);
}

.bannerOverlay {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.bannerTitle {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.bannerText {
  font-size: 18px;
  margin-bottom: 20px;
}

.ctaButton {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 12px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #0056b3;
}