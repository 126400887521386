.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    max-height: 41vh;
    overflow-y: auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modalTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 2px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ff0000;
    z-index: 10000;
}


.closeButton:hover {
    color: #333;
}

.lectureDetails {
    margin-top: 20px;
}

.lectureList {
    margin-top: 15px;
    border-top: 1px solid #ddd;
    padding-top: 15px;
}

.lectureItem {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.editMode label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.editInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.editInput:focus {
    border-color: #333;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.viewMode p {
    margin: 5px 0;
}

.lectureButton {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.lectureButton svg {
    margin-left: 5px;
}

.lectureButton:hover {
    background-color: #0056b3;
}

.saveButtonContainer {
    text-align: right;
    margin-top: 20px;
}

.saveButton {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton:hover {
    background-color: #218838;
}
