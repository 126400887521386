.learnContainer {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  margin: 0 auto;
  min-height: 80vh;
}
.headText {
  margin: 22px auto;
  color: #d8eef880;
  font-size: 13px;
  letter-spacing: 1px;
  word-spacing: 3px;
  text-align: center;
}
.defaultMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 2rem;
}

.defaultMessage p {
  margin: 0;
  line-height: 1.5;
}
.errorMessageContainer {
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 1rem;
  animation: slideDown 0.3s ease-out;
}

.errorMessageContainerContent {
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  animation: slideDown 0.3s ease-out;
}

.errorMessage {
  color: #d32f2f;
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.5;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
.formSection {
  flex: 0 0 400px;
  border: 0.01px solid #1da4a2;
  padding: 1.5rem;
  height: fit-content;
}

.contentSection {
  flex: 1;
  animation: fadeIn 0.5s ease-out;
}

.formBoxLearn {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.dropContainer {
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 3px;
}

.buttonLearnPage {
  padding: 12px 24px;
  background: #33dcc8;
  color: rgb(14, 14, 14);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  margin: 1rem 0;
  font-weight: 500;
  transition: background 0.3s ease;
}

.buttonLearnPage:hover {
  background: #2bb3a3;
}

.errorMessage {
  color: #dc3545;
  font-size: 0.875rem;
  margin: 0.5rem 0;
}

.courseDetailsCome {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.testDetails {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  height: 100%;
}

@media (max-width: 1024px) {
  .learnContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .formSection {
    flex: 0 0 350px;
  }
}

@media (max-width: 768px) {
  .learnContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .formSection {
    flex: none;
    width: 100%;
  }

  .contentSection {
    width: 100%;
    max-height: none;
  }
}

@media (max-width: 480px) {
  .learnContainer {
    padding: 0.5rem;
  }

  .formSection,
  .contentSection {
    padding: 1rem;
  }

  .buttonLearnPage {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}