.main {
    width: 100vw;
    padding: 10px; /* Add padding to prevent content from touching screen edges */
  }
  
  .tableWrapper {
    margin: 100px auto;
    width: 90%; /* Reduce width on smaller screens */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto; /* Allow horizontal scrolling for small screens */
  }
  
  /* Media query for tablets and mobile screens */
  @media (max-width: 768px) {
    .tableWrapper {
      flex-direction: column;
      margin: 50px auto; /* Adjust margins */
    }
  
    .uploader_table th,
    .uploader_table td {
      padding: 8px; /* Reduce padding for smaller screens */
    }
  
    .uploader_table {
      width: 100%;
      font-size: 0.9rem; /* Adjust font size for readability on mobile */
    }
  }
  
  /* Media query for very small screens */
  @media (max-width: 480px) {
    .tableWrapper {
        flex-direction: column;
        margin: 50px auto; /* Adjust margins */
      }
  
    .uploader_table th,
    .uploader_table td {
      padding: 6px; /* Reduce padding further for very small screens */
      font-size: 0.8rem; /* Smaller font size for very small screens */
    }
  
    .uploader_table {
      font-size: 0.8rem;
    }
  }
  