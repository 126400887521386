/* Base Styles */
.input_wrapper {
  border: 0.2px solid #a6f6f62e;
  padding: 20px;
  display: flex;
  align-items: center;
  width: 98%;
  border-radius: 4px;
  margin-bottom: 10px;
}

.regfields {
  background: none;
  outline: none;
  color: white;
  border: none;
  width: 100%;
  text-align: left;
}

.icon {
  color: #00BCD4;
}

.input_wrapper_file_upload {
  border: 0.2px solid #a6f6f62e;
  padding: 20px;
  display: flex;
  width: 98%;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.input_wrapper_file_upload span {
  color: #808080bf;
  font-size: 14px;
}

.savebttn {
  padding: 10px;
  border-radius: 6px;
  background-color: #00d4ce;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
  font-size: 16px;
}

/* Tablet Styles */
@media (max-width: 768px) {
  .input_wrapper,
  .input_wrapper_file_upload {
    border: 0.2px solid #a6f6f62e;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 98%;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .icon {
      margin-top: 10px;
  }

  .regfields {
      font-size: 14px;
  }

  .savebttn {
      font-size: 14px;
      padding: 8px;
  }
}

/* Mobile Styles */
@media (max-width: 480px) {
  .input_wrapper,
  .input_wrapper_file_upload {
      padding: 10px;
  }

  .regfields {
      font-size: 12px;
  }

  .input_wrapper_file_upload span {
      font-size: 12px;
  }

  .icon {
      font-size: 20px;
      margin-top: 5px;
  }

  .savebttn {
      width: 100%;
      font-size: 14px;
      padding: 8px;
  }
}
