.main {
  display: flex;
  flex-direction: column;
  padding: 54px;
}

.heading {
  padding: 10px;
  border: 0.01px solid rgba(0, 255, 255, 0.453);
  color: white;
  font-size: 19px;
  font-weight: 550;
  border-radius: 8px;
}

.container1 {
  padding: 24px;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 44px;
}

.containerCard {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 2px solid #00f5fa;
  padding: 25px;
  border-radius: 8px;
  height: 10rem;
  background: #000000;
  text-align: center;
}

.bookIcon {
  font-size: 84px;
  margin-bottom: 10px;
}

/* Mobile responsiveness */

@media (max-width: 768px) {
  .main {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .heading {
    font-size: 16px; /* Slightly smaller font size on mobile */
    padding: 8px; /* Adjust padding */
  }

  .container1 {
    flex-direction: column; /* Stack elements vertically */
    gap: 20px; /* Reduce gap */
  }

  .containerCard {
    height: auto; /* Allow dynamic height based on content */
    padding: 15px; /* Reduce padding */
  }

  .bookIcon {
    font-size: 64px; /* Reduce icon size */
  }
}

@media (max-width: 480px) {
  .main {
    padding: 10px; /* Further reduce padding for very small screens */
  }

  .heading {
    font-size: 14px; /* Smaller font size */
  }

  .container1 {
    gap: 10px; /* Smaller gap between items */
  }

  .containerCard {
    padding: 10px; /* Reduce padding for cards */
    border-radius: 6px; /* Slightly smaller border radius */
  }

  .bookIcon {
    font-size: 48px; /* Further reduce icon size */
  }
}
