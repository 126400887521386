/* Main form container */
.form_box {
  display: flex;
  flex-direction: column;
  gap: 25px; /* Reduced gap for better spacing on smaller screens */
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 400px; /* Limit form size on larger screens */
  padding: 20px; /* Add padding for better spacing */
}

/* Input fields */
.fields {
  outline: none;
  border: none;
  background: none;
  color: rgb(249, 248, 248);
  text-align: left;
  width: 100%;
  font-size: 1rem; /* Ensure readable text size */
}

.fields::placeholder {
  color: rgb(164, 162, 162);
}

/* Form buttons */
.button {
  border-radius: 6px;
  height: 3em;
  background: #33dcc8b4;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  font-size: 1rem;
  width: 100%; /* Ensure button takes full width on mobile */
}

.button:hover {
  background: #2ac4b2b3;
}

/* Icon styling */
.icon {
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}

/* Input wrapper */
.input_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border: 0.5px solid #36d3d133;
  padding: 15px;
  border-radius: 6px;
}

/* Media query for tablets and smaller screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .form_box {
    gap: 20px;
    max-width: 90%; /* Reduce max width for tablet */
  }

  .fields {
    font-size: 0.95rem;
  }

  .button {
    font-size: 0.95rem;
    padding: 10px;
  }

  .icon {
    font-size: 1.1rem;
  }

  .input_wrapper {
    padding: 12px;
  }
}

/* Media query for mobile devices (max-width: 768px) */
@media (max-width: 768px) {
  .form_box {
    gap: 15px;
    padding: 0 20px;
  }

  .fields {
    font-size: 0.9rem;
  }

  .button {
    font-size: 0.9rem;
    padding: 8px;
  }

  .icon {
    font-size: 1rem;
  }

  .input_wrapper {
    padding: 10px;
  }
}

/* Media query for very small mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .form_box {
    gap: 10px;
    padding: 0 15px;
  }

  .fields {
    font-size: 0.85rem;
  }

  .button {
    font-size: 0.85rem;
    padding: 6px;
  }

  .icon {
    font-size: 0.9rem;
  }

  .input_wrapper {
    padding: 8px;
  }
}
