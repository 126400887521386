/* Base Style for Larger Screens (Desktops) */
.formContainer {
    border: 0.01px solid #74e1e6;
    width: 50%;
    padding: 50px;
    margin: 120px auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tablet Style */
@media (max-width: 768px) {
    .formContainer {
        width: 70%;
        padding: 40px;
        margin: 80px auto;
    }
}

/* Mobile Style */
@media (max-width: 480px) {
    .formContainer {
        width: 90%;
        padding: 20px;
        margin: 60px auto;
    }
}
