.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #2b2b2b;
    color: #fff;
    padding: 30px;
    width: 60%;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    height: 30rem;
    overflow-y: scroll;
}
.modalContent::-webkit-scrollbar {
    width: 8px;
  }
  
  .modalContent::-webkit-scrollbar-track {
    background: #1c1c1c;
  }
  
  .modalContent::-webkit-scrollbar-thumb {
    background-color: #39ff14; /* Fluorescent green for the scrollbar thumb */
    border-radius: 10px;
  }
  
  .modalContent::-webkit-scrollbar-thumb:hover {
    background-color: #32d300; /* Slightly lighter green on hover */
  }
  
  .modalContent::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  

.modalTitle {
    font-size: 23px;
    margin-bottom: 15px;
    text-align: center;
    color: #00ffbc;
}


.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.lectureDetails h3 {
    font-size: 19px;
    margin-bottom: 10px;
    color: #00fcf5;
    text-align: center;
}

.lectureList {
    list-style-type: none;
    padding: 0;
}

.lectureItem {
    margin-bottom: 15px;
    padding: 21px;
    background-color: #3c3c3c;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.lectureItem p {
    margin: 5px 0;
}

.lectureItem strong {
    color: #00ffb4;
}

.lectureLink {
    color: #1db954;
    text-decoration: none;
    transition: color 0.3s ease;
}

.lectureLink:hover {
    color: #1ed760;
}

.lectureItem:hover {
    background-color: #444;
}


.lectureButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.lectureButton:hover {
    background-color: #0056b3;
}
