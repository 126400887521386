.uploader_table {
  width: 100%;
  padding: 20px;
  border: 0.01pc solid #00ffff52;
  overflow-x: auto; /* Make the table scrollable on smaller screens */
}

/* Adjust header panel for mobile */
.header_panel {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack elements on small screens */
  align-items: center;
}

.header_panel .right_pane {
  width: 100%;
  border: 0.001px solid #4cafa2;
  border-radius: 54px;
  padding: 10px;
}

/* Make search input full width on mobile */
.header_panel .right_pane .table_search .uploader_table_input {
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  outline: none;
}

/* Button adjustments for mobile */
.button_container {
  display: flex;
  justify-content: center; /* Center the buttons on mobile */
  align-items: center;
  margin-top: 1em;
  width: 100%;
  padding: 12px;
}

/* Table column and cell adjustments */
.uploader-table th,
.uploader-table td {
  padding: 8px;
  border-bottom: 0.001px solid #8ce5e52e;
  text-align: left;
}

.uploader-table tbody tr:hover {
  background-color: #ddd; /* Make the hover effect more prominent */
}

.uploader-table button {
  margin-right: 5px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #405cf5;
  color: white;
  border: none;
  border-radius: 4px;
}

/* Responsive table for small screens */
@media (max-width: 768px) {
  .uploader_table {
    padding: 10px;
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }

  .header_panel {
    flex-direction: column; /* Stack elements vertically on small screens */
  }

  .header_panel .right_pane {
    width: 100%; /* Make search input take full width */
  }

  .header_panel .right_pane .table_search .uploader_table_input {
    width: 100%; /* Full-width input on mobile */
  }

  .button_container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center;
    margin-top: 1em;
  }

  .uploader-table th,
  .uploader-table td {
    padding: 6px; /* Reduce padding for smaller screens */
  }

  .uploader-table button {
    padding: 6px 12px;
  }
}

/* Responsive adjustments for very small screens (max-width 480px) */
@media (max-width: 480px) {
  .uploader_table {
    font-size: 0.8rem; /* Further reduce font size for very small screens */
  }

  .header_panel .right_pane .table_search .uploader_table_input {
    padding: 5px;
  }

  .uploader-table th,
  .uploader-table td {
    padding: 4px;
  }
}
