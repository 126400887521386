.form_box {
    display: flex;
    flex-direction: column;
    gap: 29px;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* background-color: rgba(0, 0, 0, 0.562); */
   
  }
  .fields {
    outline: none;
    border: none;
    background: none;
    color: rgb(249, 248, 248);
    text-align: left;
    width: 100%;
  }
   
  .fields::placeholder {
    color: rgb(164, 162, 162);
  }
  
  .button {
    width: 20%;
    border-radius: 6px;
    height: 3em;
    background: #33dcc8b4;
    border: none;
    color: white;
    width: 35%;
    cursor: pointer;
  }
  
  .button:hover {
    background: #2ac4b2b3;
  }
  .icon {
    color: white;
    cursor: pointer;
    font-size: 100%;
  }
  .input_wrapper{
    display: flex;
    width: 100%;
    border: 0.5px solid #36d3d133;
    padding: 25px;
    border-radius: 6px;
  }