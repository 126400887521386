.crad {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3em;
  padding: 2%;
  align-items: center;
}

.regfields {
  background: none;
  outline: none;
  color: white;
  border: none;
  width: 100%;
  text-align: left;
}

.drop_container {
  border: 0.2px solid #a6f6f62e;
  display: flex;
  width: 98%;
  border-radius: 4px;
}

.regfields::placeholder {
  color: rgb(157, 157, 157);
}

.input_wrapper {
  border: 0.2px solid #a6f6f62e;
  padding: 20px;
  display: flex;
  width: 98%;
  border-radius: 4px;
}
.icon {
  color: #00BCD4;
}