.dashboard_button {
    width: 10%;
    border-radius: 2rem;
    height: 3em;
    background: rgb(229 223 223);
    border: none;
    color: #1c1b1b;
  
  }
  
  .dashboard_button:hover {
    background: rgb(232 225 225 / 58%);
  }
  
  .dashboard_div {
    display: flex;
    flex-direction: column;
    margin: 5%;
    gap: 1rem;
  }
  
  .search {
    width: 17%;
    position: absolute;
    left: 72%;
    top: 7%;
    height: 2rem;
    border: none;
    background: transparent;
    border-bottom: 2px solid white;
    color: white;
  }
  
  /* .student_details{
    color: white;
    display: flex;
    gap: 7rem;
    position: absolute;
    left: 31%;
    top: 10%;
  } */
  .student_details {
    display: flex;
    flex-direction: column;
    position: absolute;
    color: rgb(16, 15, 15);
    left: 48%;
    top: 5%;
    gap: 2rem;
    background: rgb(229 223 223 / 33%);
    padding: 2%;
    overflow-y: scroll;
    width: 35%;
    font-weight: 600;
  }
  
  .student_details .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .files_details {
    background: rgb(229 223 223 / 33%);
    width: 23%;
    padding: 2%;
    position: absolute;
    left: 34%;
    top: 10%;
  }
  
  .dasboard_button {
    width: 32%;
    height: 20px;
    background: rgb(49 49 49 / 67%);
    border: none;
    color: white;
    position: relative;
    left: 71%;
    top: 8px;
  }
  
  .dashboard_submit_button {
    width: 41%;
    border-radius: 2rem;
    height: 2em;
    background: rgb(49 49 49 / 67%);
    border: none;
    color: white;
    position: relative;
    left: -13%;
    top: 17px;
  
  }
  
  .dashboard_button_onchange {
    width: 10%;
    border-radius: 2rem;
    height: 3em;
    background: rgb(229 223 223);
    border: none;
    color: #1c1b1b;
    position: relative;
    left: 12%;
    top: -56px;
  }
  
  .database_modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 23%;
    height: 26rem;
    box-shadow: 10px 10px 121px 9px rgb(143 130 130 / 38%);
    gap: 29px;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 15%;
    left: 62%;
    background: rgb(251 251 251 / 66%);
  }
  
  .checkboxContainer {
    display: flex;
    gap: 35%;
    justify-content: space-around;
    align-items: center;
  }
  
  .file_button {
    display: flex;
    gap: 18%;
    justify-content: center;
    align-items: center;
  }
  
  .dashboard_button_files {
    display: flex;
    gap: 11px;
    border: none;
    padding: 12px;
    border-radius: 4px;
    background: #33dcc8b4;
    cursor: pointer;
  }
  
  .dashboard_button_files:hover {
    background: rgb(232 225 225 / 58%);
  }
  
  .dashboard_search {
    width: 20%;
    height: 3rem;
    border-radius: 2rem;
    border: none;
    padding: 2%;
  }
  
  .dashboard_button_view {
    position: relative;
    width: 6%;
    border-radius: 2rem;
    height: 3em;
    background: rgb(229 223 223);
    border: none;
    color: #1c1b1b;
    left: 21%;
    bottom: 4rem;
  }
  