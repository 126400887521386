/* Main container for the form and the right pane */
.container {
  display: flex;
  flex-direction: row;
  width: 40%; /* Width for larger screens */
  margin: 11rem auto; /* Center the form on larger screens */
  justify-content: center;
  border-radius: 15px;
  height: 32rem; /* Fixed height */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Left pane containing the form */
.left_pane {
  width: 100%;
  padding: 8%;
  background-color: #abaaaa26;
  padding-top: 6rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Right pane with sign-up prompt */
.right_pane {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #00e2ffa1;
  justify-content: center;
  align-items: center;
  color: rgb(249, 248, 248);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.text1 {
  word-spacing: 3px;
  letter-spacing: 1px;
  text-align: center; /* Center text */
}

.text2 {
  cursor: pointer;
  font-weight: bold;
  text-align: center; /* Center text */
}

/* Media query for tablets (screens smaller than 768px) */
@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    gap: 15px; /* Stack the panes vertically on mobile */
    width: 90%; /* Increase width for mobile */
    margin: 5rem auto; /* Further reduce the top margin */
    height: auto; /* Allow container height to adjust based on content */
  }


  .left_pane, .right_pane {
    padding: 5%; /* Reduce padding for smaller screens */
  }

  .left_pane {
    padding-top: 4rem; /* Adjust the padding for the left pane */
  }
}

/* Media query for mobile devices (screens smaller than 576px) */
@media (max-width: 576px) {
  .container {
    flex-direction: column-reverse;
    gap: 15px; /* Stack the panes vertically on mobile */
    width: 90%; /* Increase width for mobile */
    margin: 5rem auto; /* Further reduce the top margin */
    height: auto; /* Allow container height to adjust based on content */
  }

  .left_pane, .right_pane {
    width: 100%; /* Full width for both panes */
    border-radius: 10px; /* Round corners on all sides */
    padding: 4%; /* Reduce padding further for mobile */
  }

  .right_pane {
    margin-top: 1rem; /* Add space between the panes when stacked */
  }

  .left_pane {
    padding-top: 2rem; /* Reduce top padding for mobile */
  }
}
@media (max-width: 1186px) {
  .container {
    flex-direction: column-reverse;
    gap: 15px; /* Stack the panes vertically on mobile */
    width: 90%; /* Increase width for mobile */
    margin: 5rem auto; /* Further reduce the top margin */
    height: auto; /* Allow container height to adjust based on content */
  }

  .left_pane, .right_pane {
    padding: 5%; /* Reduce padding for smaller screens */
  }

  .left_pane {
    padding-top: 4rem; /* Adjust the padding for the left pane */
  }
}