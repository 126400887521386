/* src/Styles/Modal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Dark matte overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #1c1c1c; /* Matte black modal */
  color: white;
  padding: 45px;
  width: 60%;
  overflow-y: scroll;
  height: 30rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5); /* Soft shadow for modal */
}

/* Fluorescent Text Style */
.modalTitle,
.testDetails strong {
  text-align: center;
  color: #14ffba9e; /* Fluorescent green */
  font-weight: bold;
}

.closeButton {
  background: none;
  border: none;
  color: #f0f0f0;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.testDetails p {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.1rem;
}

.questionsContainer h4 {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.questionCard {
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid #444;
  margin-bottom: 20px;
}

.questionCard p {
  margin: 10px 0;
  font-size: 1.1rem;
  color: #f0f0f0;
}

ul {
  margin-top: 10px;
}

ul li {
  margin-left: 20px;
  list-style-type: circle;
  color: #f0f0f0;
}

/* Scrollbar Customization */
.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: #1c1c1c;
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #39ff14; /* Fluorescent green for the scrollbar thumb */
  border-radius: 10px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background-color: #32d300; /* Slightly lighter green on hover */
}

.modalContent::-webkit-scrollbar-corner {
  background-color: transparent;
}
