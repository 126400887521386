/* Base styles */
.main {
    display: flex;
    padding: 18px;
    justify-content: space-around;
    align-items: center;
    background: black;
    color: white;
    cursor: pointer;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.logo {
    height: 50px;
    width: 50px;
    bottom: 12px;
    padding: 8px;
    object-fit: contain;
    background: #a2d6ed;
    border-radius: 50%;
}

.tag {
    font-size: 18px;
    padding: 8px 12px;
}

/* Tablet view adjustments */
@media (max-width: 768px) {
    .main {
        padding: 12px;
        justify-content: space-between;
    }

    .logo {
        height: 40px;
        width: 40px;
        padding: 6px;
    }

    .tag {
        font-size: 16px;
        padding: 6px 10px;
    }
}

/* Mobile view adjustments */
@media (max-width: 480px) {
    .main {
        flex-direction: column; /* Stack elements vertically */
        padding: 10px;
        text-align: center;
    }

    .logo {
        height: 35px;
        width: 35px;
        padding: 5px;
        margin-bottom: 10px; /* Add space below the logo */
    }

    .tag {
        font-size: 14px;
        padding: 5px;
        margin: 5px 0; /* Add space between the buttons */
    }
}
