.drop_container {
  width: 50%;
  border: 0.001px solid #ffffff66;
  padding: 12px;
  border-radius: 6px;
}

.container {
  display: flex;
  padding: 26px;
  margin: 0 auto;
  gap: 58px;
  background: #000000;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.view {
  width: 50%; /* Adjust width for smaller screens */
  border-radius: 6px;
  padding: 10px;
  border: none;
  color: white;
  background: #33dcc8b4;
  cursor: pointer;
  margin: 8px auto;
  max-width: 200px; /* Set a max width for the button */
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%; /* Adjust modal width for mobile */
  width: 100%;
  position: relative;
  height: 50%;
  overflow-y: scroll;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack dropdowns vertically on mobile */
    gap: 20px;
  }

  .view {
    width: 80%; /* Full width on mobile */
  }

  .modalContent {
    max-width: 90%; /* Ensure modal fits well on smaller screens */
  }

  .drop_container {
    width: 100%; /* Full width for dropdowns */
  }
  .modalContent {
    max-width: 80%; /* Reduce modal width for smaller screens like tablets */
    padding: 15px;  /* Slightly reduce padding for smaller devices */
  }
}

@media (max-width: 480px) {
  .view {
    width: 100%; /* Full width on very small screens */
  }

  .container {
    padding: 10px;
    gap: 10px;
  }
  .modalContent {
    max-width: 95%; /* Full width on small mobile screens */
    padding: 10px;  /* Further reduce padding on mobile */
    border-radius: 6px; /* Adjust border-radius for a smoother appearance on small screens */
  }

  .drop_container {
    padding: 8px;
  }
}
