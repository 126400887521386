.database_modal {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 60px;
  gap: 29px;
  justify-content: center;
  align-items: center;
  margin: 36px auto;
  border: 0.01px solid #12bace63;
}
.progressBarContainer {
  width: 100%;
  background-color: #e0e0e0; 
  border-radius: 5px;
  margin-top: 10px;
  height: 10px;
}

.progressBar {
  height: 100%;
  background-color: #4caf50; 
  border-radius: 5px;
  transition: width 0.4s ease;
}

.progressText {
  margin-top: 5px;
  font-size: 12px;
  color: #555;
}

.checkboxContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.checkboxWrapper {
  width: 50%;
  border: 0.001px solid #4cafa2;
  display: flex;
  justify-content: center;
  gap: 58%;
  padding: 12px;
  border-radius: 6px;
}

.checkboxLabel {
  display: flex;
  gap: 11px;
  color: #dedede78;
}

.file_button {
  display: flex;
  gap: 18%;
  justify-content: center;
  align-items: center;
}

.dashboard_button_files {
  display: flex;
  gap: 11px;
  border: none;
  padding: 12px;
  border-radius: 4px;
  background: #33dcc8b4;
  cursor: pointer;
}

.dashboard_button_files:hover {
  background: rgb(0, 0, 0);
  color: white;
}

.drop_container {
  width: 50%;
  border: 0.001px solid #4cafa2;
  padding: 12px;
  border-radius: 6px;
}

.icon {
  margin-top: 2px;
  font-size: 13px;
}
.file_name_display {
  margin-top: 10px;
  font-size: 16px;
  color: white;
  font-weight: 700;
  border: 1px solid;
  padding: 25px;
  overflow-y: scroll;
  height: 16rem;
}
.file_name {
  color: rgb(246, 244, 244); 
}

.uploaded {
  color: #24dc83; 
}

.file_uploaded {
  background-color: #d4edda; /* Green background */
  color: #30d054; /* Darker green text */
}



@media (max-width: 768px) {
  .database_modal {
    width: 90%; 
    padding: 20px;
    gap: 20px;
  }

  .checkboxWrapper {
    width: 80%;
    gap: 30%;
  }

  .drop_container {
    width: 100%; 
    padding: 10px; 
  }

  .file_button {
    gap: 15px;
  }

  .dashboard_button_files {
    width: 100%; 
    padding: 10px;
    font-size: 14px;
  }

  .icon {
    font-size: 16px; 
  }
}

@media (max-width: 480px) {
  .database_modal {
    padding: 15px;
  }

  .checkboxWrapper {
    width: 100%; 
    gap: 20%;
  }

  .drop_container {
    padding: 8px; 
  }

  .dashboard_button_files {
    padding: 8px; 
    font-size: 12px;
  }

  .icon {
    font-size: 14px; 
  }
}
@media (max-width: 1374px) {
  .database_modal {
    width: 90%;
    padding: 20px;
    gap: 20px;
  }

  .checkboxWrapper {
    width: 80%; 
    gap: 30%; 
  }

  .drop_container {
    width: 100%; 
    padding: 10px; 
  }

  .file_button {
    gap: 15px;
  }

  .dashboard_button_files {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .icon {
    font-size: 16px; 
  }
}

/* File Block */
.file_block {
  display: flex;
  align-items: center;
  background-color: #121212;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  justify-content: space-between;
  width: 100%;
}

.remove_file {
  cursor: pointer;
  color: red;
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
}

