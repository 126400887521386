.createButton {
    width: 50%; /* Adjust width for smaller screens */
    border-radius: 6px;
    padding: 10px;
    border: none;
    color: white;
    background: #33dcc8b4;
    cursor: pointer;
    margin: 8px auto;
    max-width: 200px; /* Set a max width for the button */
  }
  .drop_container {
    width: 50%;
    border: 0.001px solid #ffffff66;
    padding: 12px;
    border-radius: 6px;
  }
  
  .container {
    display: flex;
    padding: 26px;
    margin: 0 auto;
    gap: 58px;
    background: #000000;
  }
  