.test_details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: rgb(255, 255, 255);
  padding: 20px;
  background-color: #1f1f1f;
  border: 1px solid rgba(144, 195, 201, 0.26);
  overflow-y: auto;
  max-height: 72vh;
  transition: all 0.3s ease;
}

/* Scrollbar styling */
.test_details::-webkit-scrollbar {
  width: 5px; /* Adjust scrollbar width for visibility */
}

.test_details::-webkit-scrollbar-track {
  background: transparent; /* Transparent track for modern feel */
}

.test_details::-webkit-scrollbar-thumb {
  background-color: #74e1e6; /* Highlight color for scrollbar */
  border-radius: 10px; /* Round the thumb for a smoother look */
}

.test_details::-webkit-scrollbar-thumb:hover {
  background: #58c3cc; /* Lighter color on hover */
}

/* Marks Styling */
.marksObtained, .fullMarks {
  color: #00bcd4; /* Aqua color for emphasis */
  font-weight: bold;
}

.fullMarks {
  color: #ff7f7f; /* Light red for contrast */
}

/* Question Container */
.questions {
  display: flex;
  gap: 4px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Options Styling */
.option_element_one {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  background-color: #2b2b2b; /* Dark background for options */
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  background-color: #333; /* Darker shade for options */
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.option:hover {
  background-color: #444; /* Slightly lighter on hover */
}

.correct_elements {
  color: rgb(0, 212, 184); /* Bright green for correct answer */
}

.incorrectAnswer {
  color: #ff4040; /* Red for incorrect answers */
  font-weight: bold;
}

.elementsMarks {
  margin-left: 10px;
  color: #ccc;
}

/* Input & Textarea */
.checkbox  {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 6px;
  border-radius: 4px;
}
.text {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 6px;
  border-radius: 4px;
}
.textarea {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 6px;
  border-radius: 4px;
}

input[type="text"]::placeholder, textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* Responsive styles for tablets and smaller devices */
@media (max-width: 1024px) {
  .test_details {
    padding: 15px;
    max-height: 60vh;
  }

  .option_element_one {
    flex-direction: column;
    gap: 8px;
  }

  .fullMarks, .marksObtained {
    font-size: 1rem; /* Adjust font size for better readability */
  }

  .elementsMarks {
    margin-left: 0; /* Adjust margin for smaller screens */
  }
}

/* Responsive styles for mobile phones */
@media (max-width: 768px) {
  .test_details {
    padding: 10px;
    max-height: 70vh;
    gap: 15px;
  }

  .questions {
    font-size: 0.9rem; /* Slightly reduce font size */
    gap: 5px;
  }

  .fullMarks, .marksObtained {
    font-size: 0.85rem;
  }

  .option_element_one {
    flex-direction: column;
    gap: 5px; /* Reduced gap for mobile */
  }

  .correct_elements {
    font-size: 0.8rem;
  }

  textarea {
    width: 100%;
    font-size: 0.85rem;
  }

  input[type="text"] {
    width: 100%;
    font-size: 0.85rem;
  }
}

/* Very small devices (phones in portrait mode) */
@media (max-width: 480px) {
  .test_details {
    padding: 5px;
    gap: 10px;
  }

  .questions {
    font-size: 0.8rem;
    gap: 5px;
  }

  .fullMarks, .marksObtained {
    font-size: 0.75rem;
  }

  .option_element_one {
    gap: 4px;
  }

  .correct_elements {
    font-size: 0.75rem;
  }
}
